<template>
  <div style="width: 100%;">
    <v-row>
      <v-col>
        <v-container>
          <v-row>
            <v-col>
              <div class="d-flex flex-row align-center">
                <h1>{{this.plural}}</h1>
                <v-btn v-if="createMode==false" @click="createMode=true" fab x-small class="ml-2" color="info"><v-icon>mdi-plus</v-icon></v-btn>
                <v-btn v-else @click="createMode=false" fab x-small class="ml-2" color="error"><v-icon>mdi-close</v-icon></v-btn>
                <v-text-field outlined dense style="width: 300px;" v-if="createMode" label="New Promotion Name" v-model="newPromotionName"/>
                <v-btn v-if="createMode==true" :loading="createPromotionLoading" @click="create" fab x-small class="ml-2" color="success"><v-icon>mdi-check</v-icon></v-btn>
              </div>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
                  style="margin-left: 10px;"
              ></v-progress-circular>
              <v-data-table
                  :headers="headers"
                  :items="allData"
                  :items-per-page="-1"
                  class="elevation-1"
                  @click:row="rowClick"
              >
                <template v-slot:item.createdBy="{ item }">
                  <span v-if="item.createdBy">{{lookupUsername(item.createdBy) }}</span>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  <span v-if="item.createdAt">{{utils.formatDate(item.createdAt) }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  import utils from '../../plugins/helpers'

  export default {
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,
        createPromotionLoading: false,
        createMode: false,
        newPromotionName: "",
        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          { text: 'ID', value: 'id' },
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Created At', value: 'createdAt' },
          { text: 'Created By', value: 'createdBy' },
        ],
        editMode: false,
        data: {
          name: "",
          location: "",
          serial: "",
          documentTypes: [],
          data: '',
          metadata: ''
        },
        allData: [],
        singular: "Promotion",
        singularLower: "promotion",
        plural: "Promotions",
        pluralLower: "promotions"
      }
    },
    async mounted(){
        await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', 'isAllowed', 'lookupUsername'])
    },
    methods: {
      rowClick(row){
        this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async create(){
        try {
          this.createPromotionLoading = true
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`, {name: this.newPromotionName})
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} Created 🎉`, "success");

          this.newPromotionName = ''
          this.createMode = false

          this.getAllData()

        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.createPromotionLoading = false
        }
      },
      async getAllData(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}`)
          if(res.data.error) throw res.data.error

          this.allData = res.data.data
          console.log(this.allData)

        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      }
    },
  }
</script>
